<template>
<div id="app">   
	<CRow>
		<CCol sm="8" md="8">
        <CCard>
          <CCardHeader>
            <h4>Assign Groups</h4>     
          </CCardHeader>
          <CCardBody> 

              <CForm>
                <CRow>
                  <CCol col="12" class="text-left">                   
                  <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                </CCol> 
                </CRow>
              </CForm> 
              
              <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Phone Number</label>
                  </b-col>   
                  <b-col sm="9"> 
                    <vue-typeahead-bootstrap
                      v-model="phoneNum"                                  
                      :data="optNumber">
                      <template #append>
                        <CButton color="success" v-on:click="actionChange()">Select</CButton>
                      </template>
                    </vue-typeahead-bootstrap>  
                  </b-col>                
              </b-row><br/>

              <CInput
                type="text" 
                label="Display Name"
                v-model="displayName"
                :readonly="true"
                horizontal                
              />             

              <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Current Wallet Group</label>
                  </b-col>   
                  <b-col sm="9"> 
                    <ul>
                    <div v-for="(value, key, index) in currWallGroup"> 
                      <li>{{ value }}</li>
                    </div>
                    </ul>
                  </b-col>                
              </b-row><br/>

              <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Select Wallet Group</label>
                  </b-col>   
                  <b-col sm="9"> 
                    <b-form-select v-model="defOptGroup" :options="optWallGroup"></b-form-select>
                  </b-col>                
              </b-row><br/> 

              <b-button v-on:click="actionSubmit()"variant="primary">Submit</b-button>                         

          </CCardBody>
        </CCard>
      </CCol>

     </CRow>

</div>
</template>

<script>
 import AuthService from '@/api/AuthService.js'; 
 import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
 import Multiselect from 'vue-multiselect';
 
  export default { 
    name: 'assignee-wallets',
    components: {      
      VueTypeaheadBootstrap, 
      Multiselect
    },     
    data() {
      return { 
          addStatus:[],
          currentWallGroup:'',
          currWallGroup:['Not Displayed'],
          defOptGroup:'',
          optWallGroup:[], 
          baseoptWallGroup:[],
          SelectWallGroup:[],               
          baseItems:[],
          baseUserId:[],
          optNumber:[],          
          phoneNum:'',
          userId:'',
          displayName:'',       
          seen:false,
          msg :'',
          color:''
        };      
    },
    created () {        
        this.getUserList();                 
    },
    methods: {

      toggleGroupSelected(value){ //console.log(value.name);

        if(!this.SelectWallGroup.includes(value.name)){
            this.SelectWallGroup.push(value.name);            
        }

      },

      toggleGroupUnSelect(value){

        for( var i = 0; i < this.SelectWallGroup.length; i++){     
            if (this.SelectWallGroup[i] === value.name) {           
               this.SelectWallGroup.splice(i, 1); 
            }          
         } 
      },

      actionSubmit(){
      
        let inputs = {}; let params = {}; var validate = [];        
        
        params["groupId"] = this.defOptGroup;
        params["userId"]  = this.userId;  

        for (var key in params) {
          if(!params[key]) { validate.push(false); } else { validate.push(true); }
        }       

        if(!validate.includes(false)){ //console.log(params);

            this.addWalletUser(params);   

            /*var GroupList = this.SelectWallGroup;
            for (const item of GroupList) {
                var row = item.split("|");
                inputs["userId"]  = this.userId;
                inputs["groupId"] = row[0];                
                setTimeout( () =>  this.addWalletUser(inputs) , 5000);
            }

            if(!this.addStatus.includes("failed")){
                this.msg   = "Assign New Group Invalid!"; 
                this.color = 'danger'; this.seen  = true;
                setTimeout( () =>  this.seen=false , 5000);  
            } else {
                this.msg   = 'Add Wallet Group successfully'; 
                this.color = 'success';  this.seen  = true;
                setTimeout( () =>  this.seen=false , 5000);  

                this.phoneNum='';
                this.userId='';
                this.displayName='';
                this.currentWallGroup='';
                this.SelectWallGroup=[];
                this.addStatus=[];

            }*/            
        } else {           
           this.msg   = 'Data Not Complete, All Data Required!'; 
           this.color = 'warning'; this.seen  = true;
           setTimeout( () =>  this.seen=false , 5000);
        }        

      },

      actionChange(){
        if(this.phoneNum){          
          var temp = this.baseItems;
          var phoneNum = this.phoneNum;
          var user = this.baseUserId;       
          let index = temp.findIndex(temp => temp === phoneNum );
          this.userId = user[index];
          var detail = this.getUserDetail(user[index]);
          this.currWallGroup=[]; 
          this.getWalletGroupList();
        } else {
           this.msg   = 'Please select phone number first !'; 
           this.color = 'warning'; this.seen  = true;
           setTimeout( () =>  this.seen=false , 5000);
        }
      },

      addWalletUser: function(params) {
        AuthService.addWalletUser(params).then(resp => {
            //console.log(resp);
              if(resp.message=='Success'){
           
                this.msg   = 'Add Wallet Group successfully'; 
                this.color = 'success';  this.seen  = true;
                setTimeout( () =>  this.seen=false , 5000);  

                this.phoneNum='';
                this.userId='';
                this.displayName='';
                this.currWallGroup=[];
                this.defOptGroup='';  

              } else {                
                
                this.msg   = resp.data.message; 
                this.color = 'warning'; this.seen  = true;
                setTimeout( () =>  this.seen=false , 5000);  
              }
          
        }, error => {
            console.log('error');         
        });
      },

      getWalletGroupList: function() {
        AuthService.getWalletGroupList().then(resp => { //console.log(resp);
         
          var items = resp; var wallets = []; var temp = [];
          for (var key in items) {
            var listGroup = items[key].name;
            var listGroupId = items[key].groupUniqueNumber;
            temp.push(listGroup+"|"+listGroupId);         
          }
          temp.sort();
          for (var key2 in temp) {
            var value = temp[key2].split("|");
            var name = value[0] +' ( '+ value[1] +' ) '; 
            var code = value[1];
            wallets.push({ value: code , text: name }); name='';
          }

          this.optWallGroup = wallets;
          this.baseoptWallGroup = wallets;

        }, error => {
          this.loading = false;
        });
      },

      getUserDetail: function(id) {
        AuthService.getUserDetail(id).then(resp => {

          var items = resp.wallets;
          var wallets = [];
          for (var key in items) {
            var listGroup = items[key].walletGroup.name;
            wallets.push( listGroup );
          }

          this.displayName = resp.displayName;
          this.currentWallGroup = wallets.join(",");
          this.currWallGroup = wallets;

        }, error => {
          this.loading = false;
        });
      },  

      getUserList: function() { 
          AuthService.getUserList({page:0, size:10000}).then(resp => {
            //console.log(resp.payload);

              var users = resp.payload;
              var items = []; var userId=[];

              for (var key in users) {
                  items.push(users[key].mobileNumber.number);
                  userId.push(users[key].userIdentify.userId);
              }

              this.baseItems = items;
              this.baseUserId = userId;
              this.optNumber = items;

           }, error => {
            console.log('error');    
          });
      },          

      getConfigList: function() { 
          AuthService.getConfigList().then(resp => {
            var currSrc=[]; 
            var arrcurrency = resp.currency;
            for (const item of arrcurrency){ currSrc.push({ value: item , text: item }); } //console.log(currSrc);
            //this.optCurrency = currSrc;
           }, error => {
            console.log('error');    
          });
      },     
      
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>





